<template>
  <!--begin::Sigur-->
  <div v-if="isCan" class="card card-custom">
    <!--begin::Header-->
    <div class="card-header card-header-tabs-line flex-wrap">
      <div class="card-title">
        <h3 class="card-label font-weight-bolder text-dark">Sigur</h3>
      </div>
      <div class="card-toolbar">
        <b-nav tabs class="nav nav-tabs nav-bold nav-tabs-line">
          <b-nav-item to="#auth" :active="$route.hash === '#auth' || $route.hash === ''">
            {{ $t("MANAGEMENT.AUTHORIZATION_TITLE") }}
          </b-nav-item>
          <b-nav-item to="#access" :active="$route.hash === '#access'"
                      @click="onAccessPage()">
            {{ $t("MANAGEMENT.ACCESS_POINTS_TITLE") }}
          </b-nav-item>
          <b-nav-item to="#settings" :active="$route.hash === '#settings'"
                      @click="onSettingsPage()">
            {{ $t("MANAGEMENT.SETTINGS_TITLE") }}
          </b-nav-item>
          <b-nav-item to="#logs" :active="$route.hash === '#logs'"
                      @click="onLogsPage()">
            {{ $t("MANAGEMENT.LOGS_TITLE") }}
          </b-nav-item>
        </b-nav>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div v-if="isLoading" class="card-body">
      <div class="tab-content">
        <div :class="['tab-pane', { 'active': $route.hash === '#auth' || $route.hash === '' }]" class="p-2">
          <div class="row">
            <h5 class="col-md-12 card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.AUTHORIZATION_TITLE") }}
              <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">
                {{ $t("MANAGEMENT.AUTHORIZATION_DESC") }}
              </span>
            </h5>
          </div>
          <div class="row">
            <div class="col-lg-9 col-xl-7">
              <form ref="form" @submit.stop.prevent="handleSubmit('token')">
                <div class="form-group row align-items-center my-10"
                     label-for="name-input"
                     :invalid-feedback="$t('MANAGEMENT.TOKEN_INPUT_INVALID_FEEDBACK')"
                     :state="nameState">
                  <label class="col-md-4 col-lg-3 col-xl-3 col-form-label font-weight-bolder">{{ $t("MANAGEMENT.TOKEN_INPUT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></label>
                  <div class="col-md-8 col-lg-9 col-xl-9">
                    <b-form-input
                        :disabled="!isCanEdit"
                        id="name-input"
                        :placeholder="$t('MANAGEMENT.TOKEN_INPUT_PLACEHOLDER')"
                        v-model="sigurAccessToken.value"
                        :state="nameState"
                        required
                    ></b-form-input>
                  </div>
                </div>
                <b-button v-if="isCanEdit"
                          @click="handleOk($event, 'token')"
                          class="btn bg-success text-light font-weight-bolder font-size-sm">{{ $t("MANAGEMENT.SAVE_BUTTON") }}</b-button>
              </form>
            </div>
          </div>
        </div>
        <div :class="['tab-pane', { 'active': $route.hash === '#access' }]" class="p-2">
          <div class="row">
            <h5 class="col-md-9 card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.ACCESS_POINTS_TITLE") }}
              <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">
                {{ $t("MANAGEMENT.ACCESS_POINTS_DESC") }}
              </span>
            </h5>
            <div class="col-md-3 card-toolbar">
              <!--begin::Button-->
              <b-button v-if="isCanEdit"
                        v-b-modal.modal-add-edit-sigur-point
                        @click="onAddAccessPoint"
                        class="btn bg-light-primary font-weight-bolder font-size-sm float-right">
                <i class="fas fa-plus icon-md"></i>{{ $t("MANAGEMENT.ADD_ACCESS_POINTS_BUTTON") }}
              </b-button>
              <!--end::Button-->
            </div>
          </div>
          <!--begin::Search-->
          <div v-if="sigurAccessPoints.items.length > 0 || notFound"
               class="row align-items-center my-7">
            <div class="col-md-6 col-lg-6 col-xl-6">
              <div class="row align-items-center">
                <div class="col-md-4 my-2 my-md-0">
                  <div class="input-icon">
                    <input type="text" class="form-control" v-model="filter_name" :placeholder="$t('SELECT.SEARCH')" />
                    <span><i class="flaticon-search text-muted"></i></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-xl-6">
              <MainSelect
                  :selected-floor="floor"
                  @changeNotFound="toggleNotFound"/>
            </div>
          </div>
          <!--end::Search-->
          <!--begin::Table-->
          <template v-if="sigurAccessPoints.items.length > 0 || notFound">
            <b-table
                id="sigur-points-table"
                class="table-vertical-center"
                show-empty
                :items="sigurAccessPoints.items"
                :fields="fields">
              <template #empty="scope">
                <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
              </template>
              <template #head()="data">
                <span class="pl-0 text-muted font-weight-lighter">{{ data.label }}</span>
              </template>
              <template #head(actions)="data">
                <span class="pl-0 text-muted font-weight-lighter float-right">{{ data.label }}</span>
              </template>
              <template #cell(name)="data">
                <router-link :disabled="!isCanEdit"
                             :event="isCanEdit ? 'click' : ''"
                             :style="isCanEdit ? 'text-decoration: underline': ''"
                             :to="{ name: 'access-point', params: { id: data.item.id } }">
                  <h6 class="font-weight-bolder text-dark mb-0">{{ data.item.name }}</h6>
                </router-link>
              </template>
              <template #cell(sigurId)="data">
                <p class="font-size-md mb-0">{{ data.item.reference_id }}</p>
              </template>
              <template #cell(office)="data">
                <p class="font-size-md mb-0">{{ data.item.office.name }}</p>
              </template>
              <template #cell(floor)="data">
                <span>{{ data.item.floor.name }}</span>
              </template>
              <template #cell(actions)="data">
              <span v-if="isCanEdit" class="float-right">
                <b-button class="btn btn-icon btn-light btn-hover-primary btn-sm"
                          @click="onEditAccessPoint(data.item)"
                          v-b-modal.modal-add-edit-sigur-point
                          v-b-tooltip.hover.v-dark="$t('MANAGEMENT.EDIT_TOOLTIP_TEXT')">
                  <Edit/>
                </b-button>
                <router-link class="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                             v-b-tooltip.hover.v-dark="$t('MANAGEMENT.SETTING_ACCESS_TOOLTIP_TEXT')"
                             :to="{ name: 'access-point', params: { id: data.item.id } }">
                  <Settings/>
                </router-link>
                <b-button class="btn btn-icon btn-light btn-hover-primary btn-sm"
                          @click="onDeleteAccessPoint(data.item)"
                          v-b-modal.modal-delete-sigur-point
                          v-b-tooltip.hover.v-dark="$t('MANAGEMENT.DELETE_TOOLTIP_TEXT')">
                  <Trash/>
                </b-button>
              </span>
              </template>
            </b-table>
          </template>
          <!--end::Table-->
          <!--begin: Pagination-->
          <Pagination
              v-if="sigurAccessPoints.items.length > 0 || notFound"
              name="sigur-points-table"
              :pagination="sigurAccessPoints.pagination"
              @changePageLimit="changePageLimit">
          </Pagination>
          <!--end: Pagination-->
          <!--begin::Dummy-->
          <template v-if="sigurAccessPoints.items.length === 0 && !notFound">
            <div class="row">
              <div class="col-md-6 alert alert-custom alert-light-warning fade show ml-4 my-5" role="alert">
                <div class="alert-icon"><Warning/></div>
                <div class="alert-text text-secondary">{{ $t("MANAGEMENT.ACCESS_POINTS_NOT_YET") }}</div>
              </div>
            </div>
          </template>
          <!--end::Dummy-->
        </div>
        <div :class="['tab-pane', { 'active': $route.hash === '#settings' }]" class="p-2">
          <div class="row">
            <h5 class="col-md-12 card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.SETTINGS_TITLE") }}</h5>
          </div>
          <div class="row">
            <div class="col-lg-9 col-xl-7">
              <form ref="period" @submit.stop.prevent="handleSubmit('period')">
                <div class="form-group row align-items-center my-10"
                     label-for="name-input"
                     :invalid-feedback="$t('MANAGEMENT.PERIOD_INPUT_INVALID_FEEDBACK')"
                     :state="periodState">
                  <label class="col-md-4 col-lg-3 col-xl-3 col-form-label font-weight-bolder">
                    {{ $t("MANAGEMENT.PERIOD_INPUT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></label>
                  <div class="col-md-8 col-lg-9 col-xl-9">
                    <b-form-input
                        :disabled="!isCanEdit"
                        id="name-input"
                        :placeholder="$t('MANAGEMENT.PERIOD_INPUT_PLACEHOLDER')"
                        v-model="sigurPeriodSettings.value"
                        :state="periodState"
                        required
                    ></b-form-input>
                  </div>
                </div>
                <b-button v-if="isCanEdit"
                          @click="handleOk($event, 'period')"
                          class="btn bg-success text-light font-weight-bolder font-size-sm">{{ $t("MANAGEMENT.SAVE_BUTTON") }}</b-button>
              </form>
            </div>
          </div>
        </div>
        <div :class="['tab-pane', { 'active': $route.hash === '#logs' }]" class="p-2">
          <div class="row">
            <h5 class="col-md-12 card-label font-weight-bolder text-dark">{{ $t("MANAGEMENT.LOGS_TITLE") }}
              <span class="d-block text-muted font-weight-lighter pt-2 font-size-sm">
                {{ $t("MANAGEMENT.LOGS_DESC") }}
              </span>
            </h5>
          </div>
          <div class="row align-items-center mt-5">
            <div class="col-lg-12 col-xl-12">
              <div class="row align-items-center">
                <div class="col-md-3 my-2 my-md-0">
                  <label class="mr-3 d-none d-md-block">{{ $t("MANAGEMENT.PERIOD_TITLE") }}</label>
                  <treeselect v-model="filter_period"
                              :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                              :multiple="false"
                              :normalizer="normalizer"
                              :clearable="false"
                              :limit="1"
                              :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                              :options="periodList">
                    <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
                  </treeselect>
                </div>
              </div>
              <!--begin::Table-->
              <div class="table-responsive">
                <b-table
                    id="azure-logs-table"
                    class="table-vertical-center"
                    show-empty
                    :items="pacsLogs.items"
                    :fields="logsFields"
                    :per-page="0"
                    :current-page="pacsLogs.pagination.page">
                  <template #empty="scope">
                    <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
                  </template>
                  <template #head()="data">
                    <span class="pl-0 text-muted font-weight-bolder">{{ data.label }}</span>
                  </template>
                  <template #cell(dateTime)="data">
                    <p class="azure-logs-date">{{ data.item.date }}</p>
                  </template>
                  <template #cell(message)="data">
                    <p class="azure-logs-message">{{ data.item.message }}</p>
                  </template>
                </b-table>
              </div>
              <!--end::Table-->
            </div>
          </div>
          <!--begin: Pagination-->
          <Pagination
              name="amenities-table"
              :pagination="pacsLogs.pagination"
              @changePageLimit="changeLogsPageLimit">
          </Pagination>
          <!--end: Pagination-->
        </div>
      </div>
    </div>
    <!--end::Body-->
    <!--begin::Add Edit Modal-->
    <AddEditSigurPointModal
        :citiesOfCountry="citiesOfCountry"
        :form="form"
        :isEdit="isEdit"
        @changeSelectedCountry="newCitiesList"
        @changeIsEdit="toggleIsEdit"
        @changeForm="clearForm"/>
    <!--end::Add Edit Modal-->
    <!--begin::Delete Modal-->
    <b-modal
        id="modal-delete-sigur-point"
        centered>
      <template #modal-header="{ close }">
        <div class="w-100">
          <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.DELETE_ACCESS_POINT_TITLE") }}</h5>
          <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
        </div>
      </template>
      <h5 class="float-left font-weight-bolder text-dark">{{ $t("MANAGEMENT.DELETE_ACCESS_POINT_DESC") }}</h5>
      <template #modal-footer>
        <div class="w-100">
          <b-button
              variant="light-primary"
              class="float-left font-weight-bolder"
              @click="resetModal">
            {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
          </b-button>
          <p class="float-right">
            <b-button
                class="font-weight-bolder"
                variant="danger"
                @click="handleDelete">
              {{ $t("MANAGEMENT.DELETE_BUTTON") }}
            </b-button>
          </p>
        </div>
      </template>
    </b-modal>
    <!--begin::Delete Modal-->
    <!--begin::Edit Period Date Modal-->
    <EditPeriodDateModal
        @changePeriodDate="changePeriodDate"/>
    <!--end::Edit Period Date Modal-->
  </div>
  <!--end::Sigur-->
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  DELETE_SIGUR_POINT, GET_ACCESS_TOKEN,
  GET_SIGUR_ACCESS_POINTS_LIST,
  PUT_ACCESS_TOKEN,
  GET_SIGUR_PERIOD_SETTINGS,
  PUT_SIGUR_PERIOD_SETTINGS,
  GET_PACS_LOGS
} from "@/core/services/store/management/integrations.module";
import { GET_FLOORS_LIST_BY_ID, GET_OFFICES_LIST, GET_OFFICES_LIST_BY_ID } from "@/core/services/store/management.module";
import { sendAmplitudeEvent } from "@/core/services/amplitude.service";

import MainSelect from "@/view/layout/extras/MainSelect";
import Pagination from "@/view/layout/pagination/Pagination";
import Warning from "../../../layout/svg/Warning";
import Edit from "@/view/layout/svg/Edit";
import Settings from "@/view/layout/svg/Settings";
import Trash from "@/view/layout/svg/Trash";
import AddEditSigurPointModal from "@/view/layout/modal/AddEditSigurPointModal";
import EditPeriodDateModal from "../../../layout/modal/EditPeriodDateModal";

export default {
  name: "Sigur",
  components: {
    MainSelect,
    Pagination,
    Warning,
    Edit,
    Settings,
    Trash,
    AddEditSigurPointModal,
    EditPeriodDateModal
  },
  mounted() {
    this.$store.dispatch(GET_SIGUR_ACCESS_POINTS_LIST).then(() => {
      this.$store.dispatch(SET_BREADCRUMB, [{ title: "Sigur" }]);
      this.$store.dispatch(GET_ACCESS_TOKEN);
      this.$store.dispatch(GET_OFFICES_LIST);
      this.isLoading = true;
      localStorage.setItem("pageNumber", JSON.stringify({ page: 1, limit: 20 }));
    })
    this.$store.dispatch(GET_SIGUR_PERIOD_SETTINGS);
    this.$store.dispatch(GET_PACS_LOGS, this.filters);
  },
  data() {
    return {
      isLoading: false,
      nameState: null,
      notFound: false,
      filter_name: '',
      fields: [
        { key: 'name', label: this.$t("MANAGEMENT.NAME_TABLE_TITLE") },
        { key: 'sigurId', label: 'ID в Sigur' },
        { key: 'office', label: this.$t("MANAGEMENT.OFFICE_TABLE_TITLE") },
        { key: 'floor', label: this.$t("MANAGEMENT.FLOOR_TABLE_TITLE") },
        { key: 'actions', label: this.$t("MANAGEMENT.ACTIONS_TABLE_TITLE") }
      ],
      isEdit: false,
      form: {
        country: null,
        city: null,
        office: null,
        floor: null,
        name: '',
        reference_id: ''
      },
      citiesOfCountry: null,
      deleteFormId: null,
      periodState: null,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
      filter_period: 1,
      filters: {
        from: moment(new Date()).add(-6, 'days').format('DD.MM.YYYY'),
        to: moment(new Date()).format('DD.MM.YYYY'),
      },
      logsFields: [
        { key: 'dateTime', label: this.$t("MANAGEMENT.DATE_TIME_TABLE_TITLE") },
        { key: 'message', label: this.$t("MANAGEMENT.DESC_TABLE_TITLE") }
      ]
    }
  },
  watch: {
    filter_name(val) {
      this.notFound = val.length > 0;
      this.$store.dispatch(GET_SIGUR_ACCESS_POINTS_LIST, { name: val });
    },
    filter_period(val) {
      if (val === 1) {
        this.filters.from = moment(new Date()).add(-6, 'days').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val === 2) {
        this.filters.from = moment(new Date()).add(-14, 'days').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val === 3) {
        this.filters.from = moment(new Date()).add(-29, 'days').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val === 4) {
        this.filters.from = moment(new Date()).startOf('month').format('DD.MM.YYYY');
        this.filters.to = moment(new Date()).format('DD.MM.YYYY');
      } else if (val === 5) {
        this.filters.from = moment().subtract(1, 'months').startOf('month').format('DD.MM.YYYY');
        this.filters.to = moment().subtract(1, 'months').endOf('month').format('DD.MM.YYYY');
      } else if (val === 6) {
        this.$bvModal.show('modal-edit-period-date');
      }
      if (val !== undefined) {
        let data = { ...this.filters, ...{ page: '1', limit: '10' } };
        this.$store.dispatch(GET_PACS_LOGS, data);
      }
    }
  },
  computed: {
    ...mapGetters([
      "sigurAccessToken",
      "sigurAccessPoints",
      "countryCityList",
      "officesList",
      "floors",
      "floor",
      "sigurPeriodSettings",
      "periodList",
      "pacsLogs"
    ]),
    isCan() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[1]))
        if (role === undefined) {
          this.$router.push({ name: 'my-office' })
          return false;
        } else {
          return true;
        }
      }
    },
    isCanEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[2]));
        return role !== undefined;
      }
    }
  },
  methods: {
    onAccessPage() {
      sendAmplitudeEvent('management-integrations-sigur-point show');
    },
    onSettingsPage() {
      sendAmplitudeEvent('management-integrations-sigur-settings show');
    },
    onLogsPage() {
      sendAmplitudeEvent('management-integrations-sigur-logs show');
    },
    newCitiesList(variable) {
      this.citiesOfCountry = this.countryCityList.find(country => country.id === variable);
      this.citiesOfCountry = this.citiesOfCountry.cities;
    },
    toggleIsEdit(variable) {
      this.isEdit = variable;
    },
    clearForm(variable) {
      this.form = variable;
    },
    toggleNotFound(variable) {
      this.notFound = variable;
    },
    onAddAccessPoint() {
      this.isEdit = false;
    },
    onEditAccessPoint(item) {
      this.isEdit = true;
      this.form = item;
      if (this.countryCityList.length !== undefined && this.form !== undefined) {
        let country = this.countryCityList.find(country => country.country === this.form.country.name);
        if (country) {
          this.form.country = country.id;
          let city = country.cities.find(city => city.name === this.form.city.name);
          if (city) {
            this.form.city = city.id;
            this.citiesOfCountry = country.cities;
            this.$store.dispatch(GET_OFFICES_LIST_BY_ID, { params: { city: city.id } }).then(() => {
              let office = this.officesList.find(office => office.id === this.form.office.id);
              this.$store.dispatch(GET_FLOORS_LIST_BY_ID, office.id).then(() => {
                this.form.office = office.id;
                let floor = this.floors.find(floor => floor.id === this.form.floor.id);
                this.form.floor = floor.id;
              });
            })
          }
        }
      }
    },
    onDeleteAccessPoint(item) {
      this.deleteFormId = item.id;
    },
    resetModal() {
      this.deleteFormId = null;
      this.$bvModal.hide('modal-delete-sigur-point');
    },
    handleDelete() {
      this.$store.dispatch(DELETE_SIGUR_POINT, this.deleteFormId)
          .then(() => {
            this.deleteFormId = null;
            let pageData = JSON.parse(localStorage.getItem("pageNumber"));
            this.changePageLimit(pageData);
          });
      this.$nextTick(() => {
        this.$bvModal.hide('modal-delete-sigur-point');
      })
    },
    checkFormValidity(name) {
      if (name === 'token') {
        const valid = this.$refs.form.checkValidity();
        this.nameState = valid;
        return valid;
      } else if (name === 'period') {
        const valid = this.$refs.period.checkValidity();
        this.periodState = valid;
        return valid;
      }
    },
    handleOk(bvModalEvt, name) {
      bvModalEvt.preventDefault();
      this.handleSubmit(name);
    },
    handleSubmit(name) {
      if (!this.checkFormValidity(name)) {
        return;
      }
      if (name === 'token') {
        this.$store.dispatch(PUT_ACCESS_TOKEN, { form: this.sigurAccessToken });
      } else if (name === 'period') {
        this.$store.dispatch(PUT_SIGUR_PERIOD_SETTINGS, { form: this.sigurPeriodSettings });
      }
    },
    changePageLimit(val) {
      localStorage.setItem("pageNumber", JSON.stringify(val));
      this.$store.dispatch(GET_SIGUR_ACCESS_POINTS_LIST, val);
    },
    changeLogsPageLimit(val) {
      let data = { ...this.filters, ...val };
      this.$store.dispatch(GET_PACS_LOGS, data);
    },
    changePeriodDate(newDate) {
      this.filters.from = newDate.from;
      this.filters.to = newDate.to;
      this.filter_period = null;
    },
  }
}
</script>

<style scoped>

</style>
