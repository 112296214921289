import { render, staticRenderFns } from "./Sigur.vue?vue&type=template&id=e812cfc4&scoped=true&"
import script from "./Sigur.vue?vue&type=script&lang=js&"
export * from "./Sigur.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e812cfc4",
  null
  
)

export default component.exports