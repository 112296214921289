<template>
  <!--begin::Add Edit Sigur Point Modal-->
  <b-modal
      id="modal-add-edit-sigur-point"
      ref="modal"
      centered
      @hidden="resetModal"
  >
    <template #modal-header="{ close }">
      <div class="w-100">
        <h5 class="float-left font-weight-bolder text-dark">{{ isEdit ? $t("MANAGEMENT.EDIT_ACCESS_POINT_TITLE") : $t("MANAGEMENT.ADD_ACCESS_POINT_TITLE") }}</h5>
        <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
      </div>
    </template>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label-for="country-input"
          :invalid-feedback="$t('MANAGEMENT.COUNTRY_INVALID_FEEDBACK')"
          :state="countryState"
      >
        <template slot="label">{{ $t("MANAGEMENT.COUNTRY_SELECT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-select
            id="country-input"
            v-model="form.country"
            :state="countryState"
            @change="selectedCountry"
            required>
          <template #first>
            <b-form-select-option :value="null" disabled>{{ $t("SELECT.DEFAULT_TEXT") }}</b-form-select-option>
          </template>
          <b-form-select-option :value="country.id" v-for="country in countryCityList" :key="country.id">{{ country.country }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
          label-for="city-input"
          :invalid-feedback="$t('MANAGEMENT.CITY_INVALID_FEEDBACK')"
          :state="cityState"
      >
        <template slot="label">{{ $t("MANAGEMENT.CITY_SELECT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-select
            id="city-input"
            v-model="form.city"
            :state="cityState"
            @change="selectedCity"
            required>
          <template #first>
            <b-form-select-option :value="null" disabled>{{ $t("SELECT.DEFAULT_TEXT") }}</b-form-select-option>
          </template>
          <b-form-select-option :value="city.id" v-for="city in citiesOfCountry" :key="city.id">{{ city.name }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
          label-for="office-input"
          :invalid-feedback="$t('MANAGEMENT.OFFICE_SELECT_INVALID_FEEDBACK')"
          :state="officeState"
      >
        <template slot="label">{{ $t("MANAGEMENT.OFFICE_SELECT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-select
            id="office-input"
            v-model="form.office"
            :state="officeState"
            @change="selectedOffice"
            required>
          <template #first>
            <b-form-select-option :value="null" disabled>{{ $t("SELECT.DEFAULT_TEXT") }}</b-form-select-option>
          </template>
          <b-form-select-option :value="office.id" v-for="office in officesList" :key="office.id">{{ office.name }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
          label-for="floor-input"
          :invalid-feedback="$t('MANAGEMENT.FLOOR_SELECT_INVALID_FEEDBACK')"
          :state="floorState"
      >
        <template slot="label">{{ $t("MANAGEMENT.FLOOR_SELECT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-select
            id="floor-input"
            v-model="form.floor"
            :state="floorState"
            required>
          <template #first>
            <b-form-select-option :value="null" disabled>{{ $t("SELECT.DEFAULT_TEXT") }}</b-form-select-option>
          </template>
          <b-form-select-option :value="floor.id" v-for="floor in floors" :key="floor.id">{{ floor.name }}</b-form-select-option>
        </b-form-select>
      </b-form-group>
      <b-form-group
          label-for="name-input"
          :invalid-feedback="$t('MANAGEMENT.NAME_ACCESS_POINT_INPUT_INVALID_FEEDBACK')"
          :state="nameState"
      >
        <template slot="label">{{ $t("MANAGEMENT.NAME_ACCESS_POINT_INPUT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-input
            id="name-input"
            :placeholder="$t('MANAGEMENT.NAME_ACCESS_POINT_INPUT_PLACEHOLDER')"
            v-model="form.name"
            :state="nameState"
            required
        ></b-form-input>
      </b-form-group>
      <b-form-group
          label-for="sigur-input"
          :invalid-feedback="$t('MANAGEMENT.ID_ACCESS_POINT_INPUT_INVALID_FEEDBACK')"
          :state="sigurIdState"
      >
        <template slot="label">{{ $t("MANAGEMENT.ID_ACCESS_POINT_INPUT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <b-form-input
            type="number"
            step="1" min="0"
            id="sigur-input"
            :placeholder="$t('MANAGEMENT.ID_ACCESS_POINT_INPUT_PLACEHOLDER')"
            v-model="form.reference_id"
            :state="sigurIdState"
            required
        ></b-form-input>
      </b-form-group>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="light-primary"
            class="float-left font-weight-bolder"
            @click="resetModal">
          {{ $t("MANAGEMENT.CANCEL_BUTTON") }}
        </b-button>
        <p class="float-right">
          <b-button
              class="font-weight-bolder"
              variant="primary"
              @click="handleOk">
            {{ isEdit ? $t("MANAGEMENT.SAVE_BUTTON") : $t("MANAGEMENT.ADD_BUTTON") }}
          </b-button>
        </p>
      </div>
    </template>
  </b-modal>
  <!--end::Add Edit Sigur Point Modal-->
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_FLOORS_LIST_BY_ID,
  GET_OFFICES_LIST_BY_ID
} from "@/core/services/store/management.module";
import {
  GET_SIGUR_ACCESS_POINTS_LIST,
  POST_NEW_SIGUR_POINT,
  PUT_SIGUR_POINT
} from "@/core/services/store/management/integrations.module";
import {
  SELECT_NEW_CITY,
  SELECT_NEW_COUNTRY,
  SELECT_NEW_FLOOR,
  SELECT_NEW_OFFICE
} from "@/core/services/store/myoffice/index";

export default {
  name: "AddEditSigurPointModal",
  mounted() {
    // this.$store.dispatch(GET_COUNTRY_CITY_LIST, { params: { is_use:  1 } });
  },
  props: {
    citiesOfCountry: {
      type: Array
    },
    form: {
      type: Object,
      default: () => {
        return {
          country: null,
          city: null,
          office: null,
          floor: null,
          name: '',
          reference_id: ''
        }
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      countryState: null,
      cityState: null,
      officeState: null,
      floorState: null,
      nameState: null,
      sigurIdState: null
    }
  },
  computed: {
    ...mapGetters([
      "countryCityList",
      "officesList",
      "floors"
    ])
  },
  methods: {
    selectedCountry() {
      this.$emit('changeSelectedCountry', this.form.country);
    },
    selectedCity() {
      this.$store.dispatch(GET_OFFICES_LIST_BY_ID, { params: { city: this.form.city } });
    },
    selectedOffice() {
      this.$store.dispatch(GET_FLOORS_LIST_BY_ID, this.form.office);
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.form.country === null ? this.countryState = false : this.countryState = true;
      this.form.city === null ? this.cityState = false : this.cityState = true;
      this.form.office === null ? this.officeState = false : this.officeState = true;
      this.form.floor === null ? this.floorState = false : this.floorState = true;
      this.nameState = valid;
      this.sigurIdState = valid;
      return valid;
    },
    resetModal() {
      let emptyForm = {
        country: null,
        city: null,
        office: null,
        floor: null,
        name: '',
        reference_id: ''
      };
      this.$emit('changeForm', emptyForm);
      this.$emit('changeIsEdit', false);
      this.countryState = null;
      this.cityState = null;
      this.officeState = null;
      this.floorState = null;
      this.nameState = null;
      this.sigurIdState = null;
      this.$store.dispatch(GET_SIGUR_ACCESS_POINTS_LIST);
      this.$bvModal.hide('modal-add-edit-sigur-point');
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      let form = {
        floor_id: this.form.floor,
        reference_id: parseInt(this.form.reference_id),
        name: this.form.name
      }
      if (this.isEdit) {
        this.$store.dispatch(PUT_SIGUR_POINT, { form, id: this.form.id })
            .then(() => this.$store.dispatch(GET_SIGUR_ACCESS_POINTS_LIST));
      } else {
        this.$store.dispatch(POST_NEW_SIGUR_POINT, form)
            .then(() => {
              this.$store.dispatch(SELECT_NEW_COUNTRY, { id: null });
              this.$store.dispatch(SELECT_NEW_CITY, { id: null });
              this.$store.dispatch(SELECT_NEW_OFFICE, { id: null });
              this.$store.dispatch(SELECT_NEW_FLOOR, { id: null })
              this.$store.dispatch(GET_SIGUR_ACCESS_POINTS_LIST)
            });
      }
      this.$nextTick(() => {
        this.$bvModal.hide('modal-add-edit-sigur-point');
      })
    },
  }
}
</script>

<style scoped>

</style>
